<template>
  <div class="cs-flex py-40">
    <img class="logo-ht" src="../../assets/images/logo_ht_genetics.png" />
    <div class="cs-flex flex-col contact">
      <p class="mb-0 cs-font-bold">CÔNG TY DI TRUYỀN CÔNG NGHỆ CAO VIỆT NAM</p>
      <P class="mb-0"><span class="cs-font-bold">VP HÀ NỘI:</span> P406, Tầng 4, tòa nhà Telcom, Lô 18 KĐT Định Công, P. Định Công, Q. Hoàng Mai, Hà Nội</P>
      <p class="mb-0"><span class="cs-font-bold">VP HCM:</span> 186-188 Nguyễn Duy Dương, , P.3, Q.10, TP HCM</p>
      <p class="mb-0">Email: htvietnam.company@gmail.com   Tel: 0983226165</p>
    </div>
    <img src="../../assets/images/logo/image.png" class="ml-auto mr-1">
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.py-40.cs-flex {
  padding-left: 40px;
  padding-right: 40px;
}
.logo-ht {
  width: 155px;
  height: 90px;
  object-fit: cover;
  transform: translateY(-7px);
}
.contact {
  font-size: 10px;
  width: 30%;
  p {
    color:#0569C5;
    line-height: 12px;
  }
}
.cs-font-bold {
  font-weight: bold;
}
</style>